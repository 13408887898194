<template>
  <div class="bg-app vh-100 forms">
    <v-container class="py-0 px-0 bg-main pb-10" fluid>
      <v-alert
        v-if="!$online"
        dense
        tile
        color="gray"
        icon="mdi-alert-circle-outline"
        class="alert-offline text-headline"
      >
        {{ $t("t_no_connection") }}
      </v-alert>
      <div>
        <v-tabs class="mt-0" grow icons-and-text v-model="tab">
          <v-tab
            :class="{ 'white--text': $vuetify.theme.dark }"
            @click="
              tab = 0;
              resetTab();
            "
          >
            {{ $t("v_forms.t_assignments") }}
            <v-icon>mdi-account</v-icon></v-tab
          >
          <v-tab
            :class="{ 'white--text': $vuetify.theme.dark }"
            @click="
              tab = 1;
              resetTab();
            "
          >
            {{ $t("v_forms.t_library") }} <v-icon>mdi-library</v-icon></v-tab
          >
          <v-tab
            :class="{ 'white--text': $vuetify.theme.dark }"
            @click="
              tab = 2;
              resetTab();
            "
          >
            {{ $t("v_forms.t_history") }} <v-icon>mdi-history</v-icon></v-tab
          >
          <!-- SCHEDULED -->
          <v-tab-item>
            <VuePullRefresh
              :key="pullAssignmentsKey"
              :on-refresh="appRefresh"
              :config="{ startLabel: '', readyLabel: '', loadingLabel: '', pullDownHeight: 60 }"
            >
              <div class="bg-main vh-100">
                <FormAssignments :tab="0" ref="assignments" :type="'assignments'" @refresh="refresh"></FormAssignments>
              </div>
            </VuePullRefresh>
          </v-tab-item>
          <!-- LIBRARY -->
          <v-tab-item
            ><VuePullRefresh
              :key="pullLibraryKey"
              :on-refresh="appRefresh"
              :config="{ startLabel: '', readyLabel: '', loadingLabel: '' }"
            >
              <FormLibrary ref="library" :type="'library'" :tab="1" @refresh="refresh"></FormLibrary
            ></VuePullRefresh>
          </v-tab-item>
          <!-- HISTORY -->
          <v-tab-item
            ><VuePullRefresh
              :key="pullLibraryKey"
              :on-refresh="appRefresh"
              :config="{ startLabel: '', readyLabel: '', loadingLabel: '' }"
            >
              <FormSubmissions ref="submissions" :type="'submissions'" :tab="2" @refresh="refresh"></FormSubmissions>
            </VuePullRefresh>
          </v-tab-item>
        </v-tabs>
      </div>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Helpers from "@/mixins/helpers";
import FormSubmissions from "@/components/forms/FormSubmissions.vue";
import FormAssignments from "@/components/forms/FormAssignments.vue";
import FormLibrary from "@/components/forms/FormLibrary.vue";
import VuePullRefresh from "vue-pull-refresh";

export default {
  name: "Forms",
  metaInfo: {
    title: "Forms",
  },
  components: {
    FormSubmissions,
    FormAssignments,
    FormLibrary,
    VuePullRefresh,
  },
  mixins: [Helpers],
  computed: {
    ...mapGetters("user", ["user"]),
  },
  data: () => ({
    tab: 0,
    assignmentsSubmitted: false,
    formsSubmitted: false,
    loading: false,
    pullAssignmentsKey: 0,
    pullLibraryKey: 0,
  }),
  methods: {
    resetTab() {
      this.pullAssignmentsKey += 1;
      this.pullLibraryKey += 1;
    },
    showFormHistory() {
      this.formsSubmitted = !this.formsSubmitted;
    },
    showAssignmentHistory() {
      this.assignmentsSubmitted = !this.assignmentsSubmitted;
    },
    refresh() {
      if (this.tab === 1) {
        if (this.formsSubmitted) {
          this.$refs.librarysubmissions.loadSubmissions(true);
        } else {
          this.$refs.library.loadForms(true);
        }
      } else {
        if (this.assignmentsSubmitted) {
          this.$refs.assignmentsubmissions.loadSubmissions(true);
        } else {
          this.$refs.assignments.loadAssignments(true);
        }
      }
    },
    appRefresh: function () {
      this.refresh();
      return new Promise(function (resolve) {
        setTimeout(function () {
          resolve();
        }, 1000);
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.assignmentsSubmitted = false;
      vm.formsSubmitted = false;
      vm.tab = from.params.tab ? from.params.tab : 0;
      if (from.params.type == "assignmentsubmissions") {
        vm.assignmentsSubmitted = true;
      } else if (from.params.type == "librarysubmissions") {
        vm.formsSubmitted = true;
      }
    });
  },
};
</script>
<style lang="scss">
.forms {
  .v-tabs-items {
    background-color: rgb(228, 228, 228);
    .tab-wrap {
      background: rgb(228, 228, 228);
    }
  }
  .theme--dark.v-tabs-items {
    background-color: rgb(10 10 10);
    .tab-wrap {
      background: rgb(10 10 10);
    }
  }
  .v-slide-group__prev {
    display: none !important;
  }
}
</style>
