var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-main vh-100"},[(_vm.$online)?_c('v-list',{staticClass:"transparent mt-0",attrs:{"flat":"","two-line":""}},[_c('div',{staticClass:"transparent mx-1",staticStyle:{"clear":"both"}},[_c('v-row',[_c('v-col',{class:_vm.$vuetify.breakpoint.smAndUp ? '' : 'pb-0',attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"mx-3 mb-5",attrs:{"clearable":"","hide-details":"","prepend-inner-icon":"mdi-magnify","label":_vm.$t('c_formlibrary.t_find_form')},on:{"input":function($event){return _vm.loadForms(true)}},model:{value:(_vm.formsSearch),callback:function ($$v) {_vm.formsSearch=$$v},expression:"formsSearch"}})],1),_c('v-col',{class:_vm.$vuetify.breakpoint.smAndUp ? '' : 'pt-0',attrs:{"cols":"12","sm":"6","md":"5"}},[_c('v-select',{staticClass:"mx-3",attrs:{"items":[
              { text: _vm.$t('c_formlibrary.t_all_types'), val: '' },
              { text: _vm.$t('c_formlibrary.t_form'), val: 'form' },
              { text: _vm.$t('c_formlibrary.t_checklist'), val: 'checklist' },
              { text: _vm.$t('c_formlibrary.t_audit'), val: 'audit' },
            ],"label":_vm.$t('c_formlibrary.t_type'),"item-text":"text","item-value":"val"},on:{"change":function($event){return _vm.loadForms(true)}},model:{value:(_vm.formsTag),callback:function ($$v) {_vm.formsTag=$$v},expression:"formsTag"}})],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"pt-4 text-right",attrs:{"cols":"1"}},[_c('span',[(_vm.$platform == 'web')?_c('v-btn',{staticClass:"ma-0 pa-0 mr-2 mt-2",class:{ rotate: _vm.loading },attrs:{"icon":""},on:{"click":_vm.refresh}},[_c('v-icon',[_vm._v("mdi-refresh")])],1):_vm._e()],1)]):_vm._e()],1)],1),_vm._l((_vm.forms),function(item,idx){return _c('div',{key:item.id},[_c('div',[(item.day)?_c('div',{staticClass:"my-3 pl-4 subtitle-2"},[_vm._v(" "+_vm._s(item.day)+" ")]):_vm._e(),_c('v-list-item',{key:item.id + '-' + idx,staticClass:"form-item rounded mx-2 mb-3",class:{ 'grey darken-4': _vm.$vuetify.theme.dark },attrs:{"to":{
            name: 'form',
            params: {
              id: item.id,
              tab: _vm.tab,
              type: _vm.type,
            },
          }}},[_c('FormIcon',{ref:"formCompanyIcon",refInFor:true,attrs:{"item":item,"parent":'forms'}}),_c('FormListItem',{ref:"formCompanyListItem",refInFor:true,attrs:{"item":item,"parent":'forms'}})],1)],1)])})],2):_vm._e(),_c('div',[(!_vm.loading && _vm.forms.length < 1)?_c('h4',{staticClass:"pa-4"},[_vm._v(" "+_vm._s(_vm.$t("c_formassignments.t_no_library_items"))+" "+_vm._s(!_vm.$online ? " : " + _vm.$t("t_no_network_connection") : "")+" ")]):_vm._e(),(!_vm.loading && _vm.showLoadMoreForms && _vm.forms && _vm.forms.length > 0)?_c('v-btn',{staticClass:"mx-3 mb-3",on:{"click":_vm.loadMoreForms}},[_vm._v(_vm._s(_vm.$t("t_load_more")))]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }