<template>
  <div class="bg-main vh-100">
    <v-list flat two-line class="bg-main" v-if="$online">
      <div style="clear: both" class="mx-1">
        <v-row>
          <v-col cols="12" sm="6" :class="$vuetify.breakpoint.smAndUp ? '' : 'pb-0'">
            <v-text-field
              class="mx-3 mb-5"
              clearable
              hide-details
              prepend-inner-icon="mdi-magnify"
              :label="$t('c_formsubmissions.t_find_submission')"
              v-model="submissionsSearch"
              @input="searchSubmissions"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="5" :class="$vuetify.breakpoint.smAndUp ? '' : 'pt-0'">
            <v-select
              class="mx-3"
              :items="[
                { text: $t('c_formsubmissions.t_all_types'), val: '' },
                { text: $t('c_formsubmissions.t_form'), val: 'form' },
                { text: $t('c_formsubmissions.t_checklist'), val: 'checklist' },
                { text: $t('c_formsubmissions.t_audit'), val: 'audit' },
              ]"
              :label="$t('c_formsubmissions.t_type')"
              item-text="text"
              item-value="val"
              v-model="submissionsTag"
              @change="loadSubmissions(true)"
            ></v-select>
          </v-col>

          <v-col cols="1" class="pt-4 text-right" v-if="$vuetify.breakpoint.mdAndUp">
            <span>
              <v-btn
                icon
                class="ma-0 pa-0 mr-2 mt-2"
                :class="{ rotate: loading }"
                @click="refresh"
                v-if="$platform == 'web'"
                ><v-icon>mdi-refresh</v-icon></v-btn
              >
            </span>
          </v-col>
        </v-row>
      </div>
      <div v-for="(item, idx) in formatDateList(this.submissions, 'submittedOn')" :key="item.id">
        <div>
          <div
            class="my-3 mt-4 pr-4 bg-header subtitle-2"
            :style="{
              'color: rgba(255, 255, 255, 0.5)': $vuetify.theme.dark,
            }"
            v-if="item.weekStart"
          >
            {{ item.weekStart }}
            <span v-if="item.weekEnd">-</span>
            {{ item.weekEnd }}
          </div>

          <div class="my-3 pl-4 subtitle-2" v-if="item.day">{{ item.day }}</div>

          <v-list-item
            class="form-item rounded mx-2 mb-2"
            :class="{ 'grey darken-4': $vuetify.theme.dark }"
            :key="'assignment-' + item.id + '-' + idx"
            :to="{ name: 'submission', params: { id: item.id, tab: tab, type: type } }"
          >
            <FormIcon ref="formIcon" :item="item" :parent="'submissions'" />
            <FormListItem ref="formListItem" :item="item" :parent="'submissions'" />
          </v-list-item>
        </div>
      </div>
    </v-list>
    <div>
      <h4 class="pa-4" v-if="!loading && submissions.length < 1">
        {{ $t("c_formassignments.t_no_submissions") }} {{ !$online ? " : " + $t("t_no_network_connection") : "" }}
      </h4>
      <v-btn
        v-if="!loading && showLoadMoreSubmissions && submissions && submissions.length > 0"
        class="mx-3 mb-3"
        @click="loadMoreSubmissions"
        >{{ $t("t_load_more") }}</v-btn
      >
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Helpers from "@/mixins/helpers";
import FormIcon from "@/components/forms/FormIcon.vue";
import FormListItem from "@/components/forms/FormListItem.vue";
import debounce from "lodash/debounce";

export default {
  name: "FormSubmissions",
  components: { FormIcon, FormListItem },
  props: {
    type: {
      type: String,
    },
    tab: {
      type: Number,
    },
  },
  mixins: [Helpers],
  computed: {},
  data() {
    return {
      loading: false,
      submissions: [],
      submissionsParams: {},
      submissionsSearch: "",
      submissionsTag: "",
      submissionsOrderByField: "submittedOn",
      submissionsAscending: false,
      submissionsSkipNum: 0,
      submissionsTakeNum: 20,
      showLoadMoreSubmissions: false,
    };
  },
  methods: {
    ...mapActions("forms", ["getFormSubmissions"]),
    loadSubmissions(reload = false) {
      if (!this.$online) {
        return;
      }
      this.loading = true;
      this.showLoadBar();
      if (reload) {
        this.submissions = [];
        this.submissionsSkipNum = 0;
      }
      this.submissionParams = {
        "paging.orderbyfield": this.submissionsOrderByField,
        "paging.ascending": this.submissionsAscending,
        "paging.skipnum": this.submissionsSkipNum,
        "paging.takenum": this.submissionsTakeNum,
      };
      if (this.submissionsSearch) {
        this.submissionParams.contentWildcardSearch = this.submissionsSearch;
      }
      if (this.submissionsTag) {
        this.submissionParams.TagsWildcardSearch = this.submissionsTag.toLowerCase();
      }
      //this.submissionParams.hasFormAssignment = this.type == "assignmentsubmissions" ? true : false;
      this.getFormSubmissions(this.submissionParams).then((r) => {
        let submissions = r.data;
        if (submissions) {
          submissions.forEach((s) => {
            this.submissions.push(s);
          });
          r.meta?.endOfResults ? (this.showLoadMoreSubmissions = false) : (this.showLoadMoreSubmissions = true);
        }
        this.loading = false;
        this.hideLoadBar();
      });
    },
    async loadMoreSubmissions() {
      this.submissionsSkipNum = this.submissions.length;
      this.loadSubmissions();
    },
    searchSubmissions: debounce(function () {
      this.loadSubmissions(true);
    }, 500),

    refresh() {
      this.loadSubmissions(true);
    },
  },
  mounted() {
    this.submissionsSearch = "";
    this.submissionsTag = "";
    this.loadSubmissions(true);
  },
};
</script>
<style lang="sass"></style>
