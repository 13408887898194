<template>
  <div class="bg-main vh-100">
    <v-list flat two-line class="transparent mt-0" v-if="$online">
      <div style="clear: both" class="transparent mx-1">
        <v-row>
          <v-col cols="12" sm="6" :class="$vuetify.breakpoint.smAndUp ? '' : 'pb-0'">
            <v-text-field
              class="mx-3 mb-5"
              clearable
              hide-details
              prepend-inner-icon="mdi-magnify"
              :label="$t('c_formlibrary.t_find_form')"
              v-model="formsSearch"
              @input="loadForms(true)"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="5" :class="$vuetify.breakpoint.smAndUp ? '' : 'pt-0'">
            <v-select
              class="mx-3"
              :items="[
                { text: $t('c_formlibrary.t_all_types'), val: '' },
                { text: $t('c_formlibrary.t_form'), val: 'form' },
                { text: $t('c_formlibrary.t_checklist'), val: 'checklist' },
                { text: $t('c_formlibrary.t_audit'), val: 'audit' },
              ]"
              :label="$t('c_formlibrary.t_type')"
              item-text="text"
              item-value="val"
              v-model="formsTag"
              @change="loadForms(true)"
            ></v-select>
          </v-col>
          <v-col cols="1" class="pt-4 text-right" v-if="$vuetify.breakpoint.mdAndUp">
            <span>
              <v-btn
                icon
                class="ma-0 pa-0 mr-2 mt-2"
                :class="{ rotate: loading }"
                @click="refresh"
                v-if="$platform == 'web'"
                ><v-icon>mdi-refresh</v-icon></v-btn
              >
            </span>
          </v-col>
        </v-row>
      </div>
      <div v-for="(item, idx) in forms" :key="item.id">
        <div>
          <div class="my-3 pl-4 subtitle-2" v-if="item.day">
            {{ item.day }}
          </div>
          <v-list-item
            class="form-item rounded mx-2 mb-3"
            :class="{ 'grey darken-4': $vuetify.theme.dark }"
            :key="item.id + '-' + idx"
            :to="{
              name: 'form',
              params: {
                id: item.id,
                tab: tab,
                type: type,
              },
            }"
          >
            <FormIcon ref="formCompanyIcon" :item="item" :parent="'forms'" />
            <FormListItem ref="formCompanyListItem" :item="item" :parent="'forms'" />
          </v-list-item>
        </div>
      </div>
    </v-list>
    <div>
      <h4 class="pa-4" v-if="!loading && forms.length < 1">
        {{ $t("c_formassignments.t_no_library_items") }} {{ !$online ? " : " + $t("t_no_network_connection") : "" }}
      </h4>
      <v-btn
        v-if="!loading && showLoadMoreForms && forms && forms.length > 0"
        class="mx-3 mb-3"
        @click="loadMoreForms"
        >{{ $t("t_load_more") }}</v-btn
      >
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Helpers from "@/mixins/helpers";
import FormIcon from "@/components/forms/FormIcon.vue";
import FormListItem from "@/components/forms/FormListItem.vue";
import debounce from "lodash/debounce";

export default {
  name: "FormLibrary",
  components: { FormIcon, FormListItem },
  props: {
    tab: {
      type: Number,
    },
    type: {
      type: String,
      default: "library",
    },
  },
  mixins: [Helpers],
  computed: {
    ...mapGetters("user", ["user"]),
  },
  data() {
    return {
      loading: false,
      forms: [],
      formsParams: {},
      formsSearch: "",
      formsOrderByField: "Id",
      formsAscending: false,
      formsSkipNum: 0,
      formsTakeNum: 20,
      formsTag: "",
      showLoadMoreForms: false,
    };
  },
  methods: {
    ...mapActions("forms", ["getFormOpens"]),
    async loadForms(reload = false) {
      if (!this.$online) {
        return;
      }
      this.loading = true;
      this.showLoadBar();
      if (reload) {
        this.forms = [];
        this.formsSkipNum = 0;
      }
      this.formsParams = {
        "paging.orderbyfield": this.formsOrderByField,
        "paging.ascending": this.formsAscending,
        "paging.skipnum": this.formsSkipNum,
        "paging.takenum": this.formsTakeNum,
        canstartwithoutassignment: true,
        isassignedtome: false,
      };
      if (this.formsSearch) {
        this.formsParams.contentWildcardSearch = this.formsSearch;
      }
      if (this.formsTag) {
        this.formsParams.tagswildcard = this.formsTag.toLowerCase();
      }
      this.getFormOpens(this.formsParams).then((r) => {
        let forms = r.data;
        if (forms) {
          forms.forEach((f) => {
            this.forms.push(f);
          });
          if (r.meta?.endOfResults) {
            this.showLoadMoreForms = false;
          } else {
            this.showLoadMoreForms = true;
          }
        }
        this.loading = false;
        this.hideLoadBar();
      });
    },
    async loadMoreForms() {
      this.formsSkipNum = this.forms.length;
      this.loadForms();
    },
    searchForms: debounce(function () {
      this.loadForms(true);
    }, 500),

    refresh() {
      this.loadForms(true);
    },
  },
  mounted() {
    this.loadForms(true);
  },
};
</script>
